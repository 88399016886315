<template>
  <div>
    <v-app-bar fixed density="compact">
      <template #prepend>
        <v-app-bar-nav-icon
          class="d-flex d-md-none d-lg-none d-xl-none"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
      </template>

      <v-toolbar-title>
        <RouterLink to="/">
          <img
            alt="Kosmoscience logo"
            height="38"
            title="Kosmoscience logo"
            src="@/assets/images/kosmo-science-logo.png"
          />
        </RouterLink>
      </v-toolbar-title>

      <v-spacer />

      <v-tabs class="d-none d-md-flex d-lg-flex d-xl-flex">
        <v-tab :to="{ name: 'home' }" exact>
          {{ $t('toolbar.home') }}
        </v-tab>

        <v-tab v-if="isSales || isSalesReadOnly || isTechnicalArea || isFinance" :to="{ name: 'proposals' }" exact>
          {{ $t('toolbar.proposals') }}
        </v-tab>

        <v-tab v-if="isSales || isTechnicalArea" :to="{ name: 'entryDocuments' }" exact>
          {{ $t('toolbar.entryDocuments') }}
        </v-tab>

        <v-tab v-if="isSales || isTechnicalArea" :to="{ name: 'studyPlans' }" exact>
          {{ $t('toolbar.studyPlans') }}
        </v-tab>

        <v-tab v-if="canAccessRecruitments" :to="{ name: 'recruitments' }" exact>
          {{ $t('toolbar.recruitments') }}
        </v-tab>

        <v-tab v-if="canAccessGroups" :to="{ name: 'groups' }" exact>
          {{ $t('toolbar.groups') }}
        </v-tab>

        <v-tab v-if="canAccessAttendance" :to="{ name: 'attendance' }" exact>
          {{ $t('toolbar.attendance') }}
        </v-tab>

        <v-tab v-if="canAccessPayments" :to="{ name: 'payments' }" exact>
          {{ $t('toolbar.payments') }}
        </v-tab>

        <v-tab v-if="isSales || isTechnicalAreaManager" :to="{ name: 'products' }" exact>
          {{ $t('toolbar.products') }}
        </v-tab>

        <v-tab
          v-if="isSales || isSalesReadOnly || isFinance || isTechnicalAreaManager"
          :to="{ name: 'customers' }"
          exact
        >
          {{ $t('toolbar.customers') }}
        </v-tab>

        <v-tab v-if="isStaff" :to="{ name: 'users' }" exact> {{ $t('toolbar.users') }} </v-tab>

        <v-divider vertical />

        <v-btn
          v-if="isSales"
          :href="`${$metabaseUrl}/dashboard/3-faturamento?moeda=BRL&data_inicial=2025-01-01&data_final=2025-12-31`"
          target="_blank"
          color="primary"
          size="small"
          variant="flat"
          class="mt-3 mx-3"
        >
          {{ $t('toolbar.proposals') }}
        </v-btn>

        <v-divider vertical />

        <v-btn
          v-if="isBoard"
          :href="`${$metabaseUrl}/dashboard/9-geral-orcamentos?data_inicial=2025-01-01&data_final=2025-12-31&moeda=BRL`"
          target="_blank"
          color="primary"
          size="small"
          variant="flat"
          class="mt-3 mx-3"
        >
          {{ $t('toolbar.dashboard') }}
        </v-btn>

        <v-divider vertical />
      </v-tabs>

      <NotificationsMenu />

      <v-divider vertical />

      <LoggedUserMenu />
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list>
        <v-list-item :title="$t('toolbar.home')" :to="{ name: 'home' }" exact />

        <v-list-item
          v-if="isSales || isTechnicalArea || isFinance"
          :title="$t('toolbar.proposals')"
          :to="{ name: 'proposals' }"
          exact
        />

        <v-list-item
          v-if="isSales || isTechnicalArea"
          :title="$t('toolbar.entryDocuments')"
          :to="{ name: 'entryDocuments' }"
          exact
        />

        <v-list-item
          v-if="isSales || isTechnicalArea"
          :title="$t('toolbar.studyPlans')"
          :to="{ name: 'studyPlans' }"
          exact
        />

        <v-list-item
          v-if="canAccessRecruitments"
          :title="$t('toolbar.recruitments')"
          :to="{ name: 'recruitments' }"
          exact
        />

        <v-list-item v-if="canAccessGroups" :title="$t('toolbar.groups')" :to="{ name: 'groups' }" exact />

        <v-list-item v-if="canAccessAttendance" :title="$t('toolbar.attendance')" :to="{ name: 'attendance' }" exact />

        <v-list-item v-if="canAccessPayments" :title="$t('toolbar.payments')" :to="{ name: 'payments' }" exact />

        <v-list-item
          v-if="isSales || isTechnicalAreaManager"
          :title="$t('toolbar.products')"
          :to="{ name: 'products' }"
          exact
        />

        <v-list-item
          v-if="isSales || isFinance || isTechnicalAreaManager"
          :title="$t('toolbar.customers')"
          :to="{ name: 'customers' }"
          exact
        />

        <v-list-item v-if="isStaff" :title="$t('toolbar.users')" :to="{ name: 'users' }" exact />

        <v-list-item
          v-if="isSales"
          :title="$t('toolbar.dashboard')"
          :href="`${$metabaseUrl}/dashboard/3-faturamento?moeda=BRL&data_inicial=2023-03-01&data_final=2023-03-31`"
          target="_blank"
        />
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script setup lang="ts">
import LoggedUserMenu from '@/components/LoggedUserMenu.vue'
import NotificationsMenu from '@/components/NotificationsMenu.vue'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

const drawer = ref<boolean>(false)

const auth = useAuthStore()

const {
  isStaff,
  isBoard,
  isSales,
  isSalesReadOnly,
  isTechnicalAreaManager,
  isTechnicalArea,
  isFinance,
  isTaRecruitmentManager,
  isTaRecruitmentCoordinator,
  isRecruitmentManager,
  isRecruitmentCoordinator,
  isRecruitmentOperator,
  isRecepcionist,
} = storeToRefs(auth)

const canAccessRecruitments = computed(
  () =>
    isTaRecruitmentManager.value ||
    isTaRecruitmentCoordinator.value ||
    isRecruitmentManager.value ||
    isRecruitmentCoordinator.value
)
const canAccessGroups = computed(
  () =>
    isTaRecruitmentManager.value ||
    isTaRecruitmentCoordinator.value ||
    isRecruitmentManager.value ||
    isRecruitmentCoordinator.value ||
    isRecruitmentOperator.value ||
    isFinance.value
)
const canAccessAttendance = computed(
  () =>
    isTaRecruitmentManager.value ||
    isTaRecruitmentCoordinator.value ||
    isRecruitmentManager.value ||
    isRecruitmentCoordinator.value ||
    isRecruitmentOperator.value ||
    isRecepcionist.value ||
    isFinance.value
)
const canAccessPayments = computed(
  () =>
    isTaRecruitmentManager.value ||
    isTaRecruitmentCoordinator.value ||
    isRecruitmentManager.value ||
    isRecruitmentCoordinator.value ||
    isRecruitmentOperator.value ||
    isRecepcionist.value ||
    isFinance.value
)
</script>
