export default {
  isValidEmail(email: string): boolean {
    if (!email) {
      return false
    }
    return email.toLowerCase().match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g) !== null
  },
  isValidPhone(phone: string): boolean {
    if (!phone || phone.length < 8) {
      return false
    }
    return phone.match(/^[0-9\-\s()]+$/) !== null
  },
  isInternationalPhone(phone: string): boolean {
    if (!phone || phone.length < 8) return false
    return phone.match(/^\+[0-9\-\s()]+$/) !== null
  },
  isValidPassword(password: string): boolean {
    return !!password && password.length >= 8
  },
  isValidCurrency(value: string | number): boolean {
    if (!value) {
      return true
    }
    value = value.toString()
    return value.match(/^[0-9]{1,3}(\.[0-9]{1,3})*,[0-9]{2}$/) !== null
  },
  moedaToAmount(valueMoeda: string | number): number {
    if (valueMoeda === null || valueMoeda === undefined) {
      return 0
    }
    valueMoeda = valueMoeda.toString()
    let signal = '+'
    if (valueMoeda && '-+'.includes(valueMoeda[0])) {
      signal = valueMoeda[0]
      valueMoeda = valueMoeda.slice(1)
    }
    const cents = valueMoeda.replace(/[^0-9]/g, '') || 0
    return Number(`${signal}${cents}`)
  },
}
